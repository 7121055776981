import { type SVGProps } from 'react';

export const TooltipArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="11"
      viewBox="0 0 26 11"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7081 1.09313C12.4538 0.462164 13.5462 0.462163 14.2919 1.09313L26 11L0 11L11.7081 1.09313Z"
        fill="currentColor"
      />
    </svg>
  );
};
