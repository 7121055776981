import {
  Background,
  ButtonStyles,
  HoverText,
  StyledArrowRightIcon,
  Text,
} from './ThemeButton.styles.js';
import { type ThemeButtonProps as BaseThemeButtonProps } from './types.js';
import { useBodyVariant } from '@/hooks/useBodyVariant.js';
import { useTemplate } from '@/hooks/useTemplate.js';
import { styled } from '@/stitches/index.js';
import { useId } from '@react-aria/utils';
import { type ComponentProps, forwardRef } from 'react';

const Button = styled('button', ButtonStyles);

type ThemeButtonProps = BaseThemeButtonProps & ComponentProps<typeof Button>;

export const ThemeButton = forwardRef<HTMLButtonElement, ThemeButtonProps>(
  ({ children, withArrow, ...props }, ref) => {
    const template = useTemplate();
    const fontFamily = useBodyVariant();
    const id = useId();

    return (
      <Button
        fontFamily={fontFamily}
        ref={ref}
        template={template.current}
        {...props}
        aria-labelledby={id}
        withArrow={withArrow}
      >
        <HoverText
          aria-hidden
          template={template.current}
        >
          {children}
        </HoverText>
        <Text
          id={id}
          template={template.current}
        >
          {children}
        </Text>
        {withArrow ? (
          <StyledArrowRightIcon template={template.current} />
        ) : null}
        <Background template={template.current} />
      </Button>
    );
  },
);
