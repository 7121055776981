import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';

export const BodyStyle = {
  color: '$defaultFont',
  variants: {
    solid: {
      true: {
        opacity: 1,
      },
    },
    variant: {
      button: {
        fontFamily: '$body',
        fontSize: '$14',
        fontWeight: 500,
        letterSpacing: '$1_5 ',
        lineHeight: pxToRem(19.6),
        textTransform: 'uppercase',
      },
      p1: {
        '@bp0': {
          fontSize: '$18',
          lineHeight: pxToRem(25.2),
        },
        '@bp2': {
          fontSize: '$24',
          letterSpacing: 'initial',
          lineHeight: pxToRem(33.6),
        },
        fontFamily: '$body',
        fontWeight: 400,
      },
      p2: {
        fontFamily: '$body',
        fontSize: '$20',
        letterSpacing: 'initial',
        lineHeight: pxToRem(28),
      },
      p3: {
        fontFamily: '$body',
        fontSize: '$14',
        fontWeight: 500,
        letterSpacing: '$1_5',
        lineHeight: pxToRem(19.6),
        opacity: 0.5,
        textTransform: 'uppercase',
      },
    },
  },
};

export const Body = styled('span', BodyStyle);
