import { type FontStacks, fontStacks } from '@/stitches/fonts.js';

export const findFontStackKey = <T>(
  targetName: T,
): keyof FontStacks | undefined => {
  return (
    (Object.keys(fontStacks).find((fontStackKey) => {
      return fontStacks[fontStackKey as keyof FontStacks].name === targetName;
    }) as keyof FontStacks) ?? undefined
  );
};
