import { ThemeButton } from '@/components/ThemeButton/ThemeButton.js';
import {
  Background,
  HoverText,
  Text,
} from '@/components/ThemeButton/ThemeButton.styles.js';
import { useInquiry } from '@/hooks/useInquiry.js';
import { type InquiryEventSection } from '@/services/analytics/propertyLibrary.js';
import { styled } from '@/stitches/index.js';
import { isTheme } from '@/stitches/isTheme.js';
import * as barcelona from '@/templates/barcelona/themes/index.js';
import * as capeTown from '@/templates/capeTown/themes/index.js';
import * as losAngeles from '@/templates/losAngeles/themes/index.js';
import * as paris from '@/templates/paris/themes/index.js';
import * as sydney from '@/templates/sydney/themes/index.js';
import * as tokyo from '@/templates/tokyo/themes/index.js';
import { type ComponentProps, forwardRef, type ReactNode } from 'react';

type InquiryButtonProps = {
  readonly children: ReactNode;
  readonly section: InquiryEventSection;
  readonly solidBg?: boolean;
  readonly withArrow?: boolean;
};

const barcelonaStyles = {
  solidBgFalse: {
    [`${isTheme([barcelona.dusk])}`]: {
      '&:hover': { borderColor: '$secondaryFont !important' },
    },
  },
  solidBgTrue: {
    [`${isTheme([barcelona.dusk, barcelona.midnight])}`]: {
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '$secondaryFont',
        [`${HoverText}`]: { color: '$secondaryFont' },
        [`${Background}`]: { opacity: 0 },
      },
      backgroundColor: 'transparent',
      borderColor: '$secondaryFont',
      [`${Background}`]: { backgroundColor: '$secondaryFont', opacity: 1 },
      [`${HoverText}`]: { color: '$pageBackground', opacity: 1 },
      [`${Text}`]: { color: '$pageBackground' },
    },
    [`${isTheme([barcelona.dusk])}`]: {
      '&:hover': {
        [`${HoverText}`]: { color: '$defaultFont' },
      },
      [`${HoverText}`]: { color: '$pageBackground' },
      [`${Text}`]: { color: '$pageBackground' },
    },
  },
};

const capeTownStyles = {
  solidBgFalse: {
    [isTheme([
      capeTown.dawn,
      capeTown.morning,
      capeTown.midday,
      capeTown.dusk,
      capeTown.midnight,
    ])]: {
      '&:hover': {
        [`${HoverText}`]: { color: '$pageBackground' },
        [`${Background}`]: { backgroundColor: '$secondaryFont', opacity: 1 },
      },
      [`${Background}`]: { opacity: 0 },
      [`${HoverText}`]: { color: '$defaultFont' },
      border: 'solid $secondaryFont',
      borderWidth: '$1',
    },
    [isTheme([capeTown.morning, capeTown.midday])]: {
      '&:hover': {
        [`${Background}`]: { backgroundColor: '$defaultFont', opacity: 1 },
      },
      border: 'solid $secondaryFont',
      borderWidth: '$1',
    },
    [isTheme([capeTown.midnight])]: {
      '&:hover': {
        border: 'solid $secondaryFont !important',
        borderWidth: '$1 !important',
      },
    },
  },
  solidBgTrue: {
    [isTheme([capeTown.dawn, capeTown.midnight, capeTown.dusk])]: {
      '&:hover': { [`${HoverText}`]: { color: '$secondaryFont' } },
      [`${Background}`]: { backgroundColor: '$secondaryFont' },
      [`${HoverText}`]: { color: '$pageBackground' },
      backgroundColor: 'unset',
    },
    [isTheme([capeTown.morning])]: {
      '&:hover': { [`${HoverText}`]: { color: '$defaultFont' } },
      [`${HoverText}`]: { color: '$pageBackground' },
      backgroundColor: 'unset',
    },
    [isTheme([capeTown.midday, capeTown.midnight])]: {
      '&:hover': { [`${HoverText}`]: { color: '$defaultFont' } },
      [`${Background}`]: { backgroundColor: '$secondaryFont' },
      [`${HoverText}`]: { color: '$defaultFont' },
      backgroundColor: 'unset',
    },
  },
};

const parisStyles = {
  solidBgFalse: {
    [isTheme([
      paris.dawn,
      paris.morning,
      paris.midday,
      paris.midnight,
      paris.dusk,
    ])]: {
      '&:hover': {
        [`${Background}`]: { backgroundColor: '$secondaryFont' },
        '&:after': { boxShadow: `inset $colors$secondaryFont 0 0 0 $sizes$1` },
      },
    },
  },
  solidBgTrue: {
    [isTheme([paris.morning])]: {
      '&:hover': {
        '&:after': { boxShadow: `inset $colors$defaultFont 0 0 0 $sizes$1` },
        [`${HoverText}`]: { color: '$defaultFont', opacity: 1 },
      },
    },
    [isTheme([paris.dawn, paris.midday, paris.midnight, paris.dusk])]: {
      '&:after': { boxShadow: `inset $colors$secondaryFont 0 0 0 $sizes$1` },
      '&:hover': {
        '&:after': { boxShadow: `inset $colors$defaultFont 0 0 0 $sizes$1` },
        backgroundColor: 'transparent',
        [`${Background}`]: { opacity: 0 },
        [`${HoverText}`]: { color: '$defaultFont' },
      },
      backgroundColor: '$secondaryFont',
      [`${Background}`]: { backgroundColor: '$secondaryFont' },
    },
  },
};

const losAngelesStyles = {
  solidBgFalse: {
    fontSize: '$14',
  },
  solidBgTrue: {
    [`${isTheme([losAngeles.dusk])}`]: {
      '&:hover': {
        backgroundColor: '$pageBackground',
        borderColor: '$defaultFont',
        [`${HoverText}`]: { opacity: 1 },
        [`${Background}`]: { backgroundColor: '$pageBackground' },
      },
      [`${HoverText}`]: { color: '$defaultFont' },
      backgroundColor: '$secondaryFont',
      borderColor: '$secondaryFont',
      color: '$pageBackground',
    },
    fontSize: '$14 !important',
  },
};

const sydneyStyles = {
  solidBgFalse: {
    [`${isTheme([sydney.midnight])}`]: {
      '&:hover': { borderColor: '$secondaryFont !important' },
      [`${Background}`]: { backgroundColor: '$secondaryFont' },
      backgroundColor: '$pageBackground',
    },
  },
  solidBgTrue: {
    [`${isTheme([sydney.dusk])}`]: {
      [`${Text}`]: { color: '$secondaryFont' },
    },
  },
};

const tokyoStyles = {
  solidBgTrue: {
    [`${isTheme([tokyo.midday])}`]: {
      '&:hover': {
        borderColor: '$defaultFont !important',
        [`${Background}`]: { backgroundColor: '$pageBackground' },
      },
      backgroundColor: '$secondaryFont',
      borderColor: '$secondaryFont',
      [`${Background}`]: { backgroundColor: '$pageBackground' },
      [`${Text}`]: { color: '$defaultFont' },
      [`${HoverText}`]: { color: '$defaultFont' },
    },
  },
};

const StyledThemeButton = styled(ThemeButton, {
  variants: {
    solidBg: {
      false: {
        [`${Background}`]: { backgroundColor: '$defaultFont' },
        [`${HoverText}`]: { color: '$pageBackground' },
        [`${Text}`]: { color: '$defaultFont' },
        '&:hover': {
          borderColor: '$defaultFont !important',
          [`${Background}`]: { backgroundColor: '$defaultFont' },
        },
        backgroundColor: '$pageBackground',
        borderColor: '$defaultFont',
        ...capeTownStyles.solidBgFalse,
        ...barcelonaStyles.solidBgFalse,
        ...parisStyles.solidBgFalse,
        ...sydneyStyles.solidBgFalse,
        ...losAngelesStyles.solidBgFalse,
      },
      true: {
        backgroundColor: '$defaultFont',
        color: '$pageBackground',
        [`${Background}`]: { backgroundColor: '$pageBackground' },
        [`${HoverText}`]: { color: '$defaultFont' },
        [`${Text}`]: { color: '$pageBackground' },
        ...barcelonaStyles.solidBgTrue,
        ...capeTownStyles.solidBgTrue,
        ...losAngelesStyles.solidBgTrue,
        ...parisStyles.solidBgTrue,
        ...sydneyStyles.solidBgTrue,
        ...tokyoStyles.solidBgTrue,
      },
    },
  },
});

export const InquiryButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof StyledThemeButton> & InquiryButtonProps
>(({ children, section, withArrow = false, ...props }, ref) => {
  const { getInquiryButtonProps, userProfileIsReceivingInquiries } =
    useInquiry();

  if (!userProfileIsReceivingInquiries) return null;

  return (
    <StyledThemeButton
      ref={ref}
      type="button"
      {...props}
      {...getInquiryButtonProps({ section })}
      withArrow={withArrow}
    >
      {children}
    </StyledThemeButton>
  );
});
