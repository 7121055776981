import { useRouter } from 'next/router';

export const useIsPage = (): {
  isHomepage: boolean;
  isProjectPage: boolean;
  isServicePage: boolean;
} => {
  const router = useRouter();
  return {
    isHomepage: ['/', '/[username]'].includes(router.pathname),
    isProjectPage: router.pathname.includes('/p/'),
    isServicePage: router.pathname.includes('/s/'),
  };
};
