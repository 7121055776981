import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon.js';
import { EASE_OUT_EXPO } from '@/constants/animation.js';
import { type FontStacks } from '@/stitches/fonts.js';
import { isTheme } from '@/stitches/isTheme.js';
import { styled } from '@/stitches/stitches.config.js';
import { BodyStyle as AmsterdamTypography } from '@/templates/amsterdam/components/Typography/Body.js';
import * as barcelona from '@/templates/barcelona/themes/index.js';
import * as capeTown from '@/templates/capeTown/themes/index.js';
import { BodyStyle as LondonTypography } from '@/templates/london/components/Typography/Body.js';
import * as losAngeles from '@/templates/losAngeles/themes/index.js';
import { BodyStyle as MadridTypography } from '@/templates/madrid/components/Typography/Body.js';
import * as sydney from '@/templates/sydney/themes/index.js';
import { type TemplateNames } from '@/templates/templateTypes.js';
import * as tokyo from '@/templates/tokyo/themes/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import { type CSS } from '@stitches/react';

const COMMON_TEXT_STYLES = {
  amsterdam: {
    ...AmsterdamTypography.variants.variant.button,
    color: '$defaultFont',
    fontSize: '$14',
    lineHeight: 1,
  },
  barcelona: {
    fontWeight: 500,
    letterSpacing: '$1_5',
    textTransform: 'uppercase',
    [isTheme([barcelona.midnight])]: {
      color: '$secondaryFont',
    },
  },
  capeTown: {},
  london: {
    ...LondonTypography.variants.variant.button,
    color: '$defaultFont',
    lineHeight: 1,
  },
  losAngeles: {
    letterSpacing: '$1_5',
    textTransform: 'uppercase',
  },
  madrid: {
    ...MadridTypography.variants.variant.button,
    lineHeight: 1,
  },
  paris: {
    fontWeight: 500,
    letterSpacing: '$0_2',
    textTransform: 'uppercase',
  },
  saoPaulo: {
    fontSize: '$14',
    fontWeight: 500,
    letterSpacing: '$1_5',
    textTransform: 'uppercase',
  },
  sydney: {
    color: '$pageBackground',
    [isTheme(sydney.midday)]: { fontWeight: 600 },
    [isTheme([sydney.dusk])]: { color: '$secondaryFont' },
    [isTheme([sydney.midnight])]: { color: '$defaultFont' },
    fontSize: '$16',
    fontWeight: 500,
    letterSpacing: pxToRem(1),
  },
  tokyo: {
    fontWeight: 500,
    letterSpacing: '$1_5',
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
};

const COMMON_HOVER_TEXT_STYLES = {
  ...COMMON_TEXT_STYLES,
  amsterdam: {
    ...COMMON_TEXT_STYLES.amsterdam,
    color: '$pageBackground',
  },
  barcelona: {
    ...COMMON_TEXT_STYLES.barcelona,
    [isTheme([barcelona.midnight])]: {
      color: '$pageBackground',
    },
  },
  london: {
    ...COMMON_TEXT_STYLES.london,
    color: '$pageBackground',
  },
  sydney: {
    ...COMMON_TEXT_STYLES.sydney,
    [isTheme([sydney.dusk])]: {
      color: '$defaultFont',
    },
  },
  tokyo: {
    ...COMMON_TEXT_STYLES.tokyo,
    [isTheme([tokyo.midday])]: {
      color: '$defaultFont',
    },
  },
};

export const Text = styled('span', {
  color: '$defaultFont',
  lineHeight: 1,
  transition: `opacity 0.5s ${EASE_OUT_EXPO}`,
  variants: {
    template: {
      ...COMMON_TEXT_STYLES,
    },
  },
});

export const HoverText = styled('span', {
  alignItems: 'center',
  color: '$pageBackground',
  display: 'inline-flex',
  inset: 0,
  justifyContent: 'center',
  lineHeight: 1,
  opacity: 0,
  padding: '$themeButtonPadding',
  position: 'absolute',
  textAlign: 'center',
  transition: `opacity 0.5s ${EASE_OUT_EXPO}`,
  userSelect: 'none',
  variants: {
    template: {
      ...COMMON_HOVER_TEXT_STYLES,
    },
  },
  width: '100%',
  zIndex: 1,
});

export const StyledArrowRightIcon = styled(ArrowRightIcon, {
  height: '$16',
  marginLeft: '$10',
  position: 'absolute',
  right: '$24',
  transition: `color 0.5s ${EASE_OUT_EXPO}, transform 0.5s ${EASE_OUT_EXPO}`,
  variants: {
    template: {
      amsterdam: {
        color: '$defaultFont',
      },
      barcelona: {
        color: 'currentColor',
      },
      capeTown: {
        color: '$defaultFont',
      },
      london: {
        color: '$defaultFont',
      },
      losAngeles: {
        color: '$defaultFont',
      },
      madrid: {
        color: '$defaultFont',
      },
      paris: {
        color: '$defaultFont',
      },
      saoPaulo: {
        color: '$defaultFont',
      },
      sydney: {
        color: '$pageBackground',
      },
      tokyo: {
        color: '$defaultFont',
      },
    },
  },
  width: '$16',
  zIndex: 2,
});

export const Background = styled('div', {
  borderRadius: '$60',
  fillRelativeParentContainer: true,
  opacity: 0,
  transition: `transform 0.5s ${EASE_OUT_EXPO}, opacity 0.5s ${EASE_OUT_EXPO}, background-color 0.5s ${EASE_OUT_EXPO}`,
  variants: {
    template: {
      amsterdam: {
        backgroundColor: '$defaultFont',
      },
      barcelona: {
        backgroundColor: '$defaultFont',
        [isTheme([barcelona.midnight])]: {
          backgroundColor: '$secondaryFont',
        },
      },
      capeTown: {},
      london: {
        backgroundColor: '$defaultFont',
      },
      losAngeles: {
        backgroundColor: '$defaultFont',
        [isTheme([losAngeles.dusk])]: {
          backgroundColor: '$secondaryFont',
        },
      },
      madrid: {
        backgroundColor: '$defaultFont',
      },
      paris: {
        backgroundColor: '$secondaryFont',
      },
      saoPaulo: {
        backgroundColor: '$defaultFont',
      },
      sydney: {
        backgroundColor: '$pageBackground',
      },
      tokyo: {
        backgroundColor: '$defaultFont',
        [isTheme([tokyo.midday])]: {
          backgroundColor: '$secondaryFont',
        },
      },
    },
  },
  zIndex: 0,
});

const INVERTED_BUTTON_STYLES = {
  backgroundColor: '$defaultFont',
  color: '$pageBackground',
  [`${Background}`]: {
    backgroundColor: '$pageBackground',
  },
  [`${HoverText}`]: {
    color: '$defaultFont',
  },
  [isTheme([sydney.midnight])]: {
    backgroundColor: '$secondaryFont',
    borderColor: '$secondaryFont',
    [`${HoverText}`]: {
      color: '$defaultFont',
    },
    [`${Text}`]: {
      color: '$defaultFont',
    },
  },
};

export const ButtonStyles: CSS & {
  variants: {
    fontFamily: Partial<{
      [key in keyof FontStacks]: CSS;
    }>;
    hoverStyle: {
      scaleFill: CSS;
      swipeDiagonal: CSS;
      swipeUp: CSS;
    };
    outline: {
      true: CSS;
    };
    template: Partial<{
      [key in TemplateNames]: CSS;
    }>;
    withArrow: {
      true: CSS;
    };
  };
} = {
  '@bp2': {
    '&:hover': {
      [`${HoverText}`]: {
        opacity: 1,
      },
      [`${Text}`]: {
        opacity: 0,
      },
      [`${StyledArrowRightIcon}`]: {
        color: '$pageBackground',
        transform: 'translate($sizes$4, 0%)',
      },
      [`${Background}`]: {
        opacity: 1,
      },
    },
  },
  alignItems: 'center',
  border: 'solid $defaultFont',
  borderRadius: '$40',
  borderWidth: '$2',
  display: 'inline-flex',
  flex: '0 0 auto',
  fontFamily: 'inherit',
  fontSize: '$16',
  height: '$themeButtonHeight',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '$themeButtonPadding',
  position: 'relative',
  variants: {
    hoverStyle: {
      scaleFill: {
        [`${Background}`]: {
          opacity: 1,
          transform: 'scale(0)',
        },
        '&:hover': {
          [`${Background}`]: {
            transform: 'scale(1)',
          },
        },
      },
      swipeDiagonal: {
        [`${Background}`]: {
          opacity: 1,
          transform: 'translate(100%, 100%)',
        },
        '&:hover': {
          [`${Background}`]: {
            transform: 'translate(0%, 0%)',
          },
        },
      },
      swipeUp: {
        [`${Background}`]: {
          opacity: 1,
          transform: 'translate(0%, 100%)',
        },
        '&:hover': {
          [`${Background}`]: {
            transform: 'translate(0%, 0%)',
          },
        },
      },
    },
    template: {
      amsterdam: {
        borderWidth: pxToRem(1),
      },
      barcelona: {
        [isTheme([barcelona.dusk, barcelona.midnight])]: {
          '&:hover': {
            [`${Background}`]: {
              backgroundColor: '$secondaryFont',
            },
            [`${HoverText}`]: {
              color: '$pageBackground',
            },
          },
          borderColor: '$secondaryFont',
          [`${Text}`]: {
            color: '$secondaryFont',
          },
          [`${HoverText}`]: {
            color: '$secondaryFont',
          },
          color: '$secondaryFont',
        },
        [isTheme([barcelona.dusk])]: {
          '&:hover': {
            borderColor: '$secondaryFont',
          },
          borderColor: '$secondaryFont',
          [`${Text}`]: {
            color: '$defaultFont',
          },
          [`${HoverText}`]: {
            color: '$defaultFont',
          },
          color: '$defaultFont',
        },
      },
      capeTown: {
        '&:hover': {
          [`${Background}`]: { opacity: 0 },
          [`${HoverText}`]: { color: '$secondaryFont' },
          border: 'solid $secondaryFont',
          borderWidth: '$1',
        },
        [`${Background}`]: {
          backgroundColor: '$secondaryFont',
          borderRadius: '$8',
          opacity: 1,
        },
        [`${HoverText}`]: {
          color: '$pageBackground',
          opacity: 1,
        },
        [isTheme([capeTown.morning])]: {
          [`${Background}`]: { backgroundColor: '$defaultFont' },
          '&:hover': {
            [`${HoverText}`]: { color: '$defaultFont' },
            border: 'solid $defaultFont',
            borderWidth: '$1',
          },
        },
        [isTheme([capeTown.midday, capeTown.midnight])]: {
          [`${HoverText}`]: { color: '$defaultFont' },
          '&:hover': {
            [`${HoverText}`]: { color: '$defaultFont' },
          },
          fontWeight: 400,
          letterSpacing: '$1_5',
        },
        border: '$1 secondaryFont',
        borderRadius: '$10',
        fontSize: '$16',
        fontWeight: 500,
        letterSpacing: '$1',
        textTransform: 'uppercase',
      },
      london: {
        padding: '$10 $30',
      },
      losAngeles: {
        '&:hover': {
          [isTheme([losAngeles.dusk])]: {
            borderColor: '$secondaryFont',
          },
          [`${Background}`]: {
            opacity: 1,
          },
          [`${HoverText}`]: {
            opacity: 1,
          },
        },
        borderWidth: pxToRem(1.5),
        fontWeight: 700,
      },
      madrid: {
        borderWidth: pxToRem(1.5),
      },
      paris: {
        '&:after': {
          borderRadius: 'inherit',
          boxShadow: `inset $colors$defaultFont 0 0 0 $sizes$1`,
          content: '',
          fillRelativeParentContainer: true,
        },
        '&:hover': {
          [`${Background}`]: {
            opacity: 1,
          },
          [`${HoverText}`]: {
            color: '$pageBackground',
            opacity: 1,
          },
        },
        '&:hover:after': {
          boxShadow: `inset $colors$secondaryFont 0 0 0 $sizes$1`,
        },
        border: 'none',
      },
      saoPaulo: { borderWidth: pxToRem(1) },
      sydney: {
        ...INVERTED_BUTTON_STYLES,
        [isTheme([sydney.morning, sydney.dawn])]: {
          [`${Text}`]: {
            fontWeight: '500 !important',
          },
        },
      },
      tokyo: {
        [isTheme([tokyo.midday])]: {
          '&:hover': {
            borderColor: '$secondaryFont',
            [`${Background}`]: {
              backgroundColor: '$secondaryFont',
              opacity: 1,
            },
            [`${HoverText}`]: {
              color: '$defaultFont',
              opacity: 1,
            },
          },
        },
      },
    },
    withArrow: {
      true: {
        paddingRight: '$50',
        [`${HoverText}`]: {
          paddingRight: '$50',
        },
      },
    },
    // eslint-disable-next-line canonical/sort-keys
    fontFamily: {
      epilogue: {
        [`${Text},${HoverText}`]: {
          marginTop: '$3',
        },
      },
    },
    outline: {
      true: {
        [isTheme([
          capeTown.dawn,
          capeTown.morning,
          capeTown.midday,
          capeTown.dusk,
          capeTown.midnight,
        ])]: {
          '&:hover': {
            [`${Background}`]: { backgroundColor: '$defaultFont', opacity: 1 },
            [`${HoverText}`]: { color: '$pageBackground' },
          },
          [`${Background}`]: {
            opacity: 0,
          },
          [`${HoverText}`]: {
            color: '$defaultFont',
            opacity: 1,
          },
          border: 'solid $defaultFont',
          borderWidth: '$2',
        },
      },
    },
  },
  whiteSpace: 'nowrap',
};
