import { useMockPreview } from './useMockPreview.js';
// eslint-disable-next-line canonical/no-restricted-imports
import { type GraphQLTaggedNode, useFragment } from 'react-relay';
import {
  type KeyType,
  type KeyTypeData,
} from 'react-relay/relay-hooks/helpers';

export type SupportedFragmentNames =
  | 'Projects_userProfile'
  | 'Services_userProfile'
  | 'UserProfile_userProfile'
  | 'UserReviews_userProfile';

type FragmentNames = {
  default: {
    name: SupportedFragmentNames;
  };
};

const MOCKABLE_FRAGMENTS: Record<
  SupportedFragmentNames,
  SupportedFragmentNames
> = Object.freeze({
  Projects_userProfile: 'Projects_userProfile',
  Services_userProfile: 'Services_userProfile',
  UserProfile_userProfile: 'UserProfile_userProfile',
  UserReviews_userProfile: 'UserReviews_userProfile',
});

const isMockableFragment = (
  currentFragmentName: string | null | undefined,
): currentFragmentName is keyof typeof MOCKABLE_FRAGMENTS => {
  if (!currentFragmentName) return false;

  return Object.hasOwnProperty.call(MOCKABLE_FRAGMENTS, currentFragmentName);
};

const resolveFragmentName = (
  fragmentInput: GraphQLTaggedNode | GraphQLTaggedNodeWithName,
): string | null => {
  if ('default' in fragmentInput) {
    return fragmentInput.default.name;
  }

  if ('name' in fragmentInput) {
    return fragmentInput.name;
  }

  return null;
};

type GraphQLTaggedNodeWithName = FragmentNames & GraphQLTaggedNode;

export const useIPFragment = <TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode | GraphQLTaggedNodeWithName,
  fragmentRef: TKey | null,
): KeyTypeData<TKey> => {
  const { isMockPreviewingEnabled, maybeMockData } = useMockPreview();

  const originalData = useFragment<TKey>(fragmentInput, fragmentRef);
  const resolvedName = resolveFragmentName(fragmentInput);
  const isMockable = isMockableFragment(resolvedName);

  if (!isMockPreviewingEnabled || !isMockable) return originalData;

  const maybeMockedData = maybeMockData<TKey>(originalData, resolvedName);

  return maybeMockedData;
};
