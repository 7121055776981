import { logger } from '@/services/logger.js';
import { serializeError } from '@contra/utilities/serializeError';

type StorageType = 'local' | 'session';

const log = logger.child({
  namespace: 'utilities:storage',
});

export const storage = (storageType: StorageType = 'local') => {
  if (typeof window === 'undefined') {
    return {
      clear: () => null,
      getItem: () => null,
      removeItem: () => null,
      setItem: () => null,
    };
  }

  const storageAdapter =
    storageType === 'local' ? window?.localStorage : window?.sessionStorage;

  return {
    clear: (): void => {
      try {
        storageAdapter?.clear();
      } catch (error) {
        log.error(
          {
            error: serializeError(error),
          },
          `Failed to clear ${storageType} storage`,
        );
      }
    },

    getItem: (key: string): string | null => {
      try {
        return storageAdapter?.getItem(key);
      } catch (error) {
        log.error(
          { error: serializeError(error), key },
          `Failed to get ${storageType} storage item`,
        );
      }

      return null;
    },

    removeItem: (key: string): void => {
      try {
        storageAdapter?.removeItem(key);
      } catch (error) {
        log.error(
          { error: serializeError(error), key },
          `Failed to remove ${storageType} storage item`,
        );
      }
    },

    setItem: (key: string, value: string): void => {
      try {
        storageAdapter?.setItem(key, value);
      } catch (error) {
        log.error(
          { error: serializeError(error), key, value },
          `Failed to set ${storageType} storage item`,
        );
      }
    },
  };
};
