import { useCustomThemeConfig } from './useCustomThemeConfig.js';
import { useTemplate } from './useTemplate.js';
import { useTheme } from './useTheme.js';
import { THEME_DEFAULT_FONTS } from '@/contexts/CustomThemeConfigContext/constants.js';
import { findFontStackKey } from '@/utilities/findFontStackKey.js';
import { useMemo } from 'react';

export const useBodyVariant = () => {
  const {
    data: { customThemeConfig },
  } = useCustomThemeConfig();
  const template = useTemplate();
  const theme = useTheme();

  return useMemo(() => {
    return findFontStackKey(
      customThemeConfig?.fontFamilyBody?.name ||
        THEME_DEFAULT_FONTS[template.current][theme.current]['body'].name,
    );
  }, [customThemeConfig?.fontFamilyBody?.name, template, theme]);
};
