import { TooltipArrow } from './TooltipArrow.js';
import { keyframes, styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import {
  Content as RadixContent,
  Portal,
  Root,
  type TooltipContentProps,
  Trigger,
} from '@radix-ui/react-tooltip';
import { type CSS } from '@stitches/react';
import { type ReactNode } from 'react';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const Container = styled('div', {
  '& > button': {
    display: 'flex',
  },
});

const StyledTrigger = styled(Trigger);

const Content = styled(RadixContent, {
  '&[data-side="bottom"]': { animationName: slideDown },
  '&[data-side="top"]': { animationName: slideUp },
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  borderRadius: '$10',
  fontFamily: '$inter',
  fontSize: '$16',
  fontWeight: 400,
  maxWidth: pxToRem(264),
  padding: '$12',
  variants: {
    variant: {
      dark: {
        backgroundColor: '$gray95',
        borderColor: '$gray20',
        borderStyle: 'solid',
        borderWidth: '$0_5',
        color: '$gray20',
      },
      light: {
        backgroundColor: '$gray20',
        borderColor: '$gray95',
        borderStyle: 'solid',
        borderWidth: '$0_5',
        color: '$gray95',
      },
    },
  },
  zIndex: '$modal',
});

const Arrow = styled('div', {
  '[data-side="bottom"] &': {
    left: '50%',
    top: '$1',
    transform: 'translateX(-50%) translateY(-100%)',
    transformOrigin: 'bottom',
  },
  '[data-side="left"] &': {
    right: '0',
    top: '$26',
    transform: 'rotateZ(90deg) translateY(-100%)',
    transformOrigin: 'bottom',
  },
  '[data-side="left"][data-align="center"] &': {
    bottom: '$50',
    top: '50%',
    transform: 'rotateZ(90deg) translateY(-100%) translateX(-50%)',
  },
  '[data-side="left"][data-align="end"] &': {
    bottom: '$50',
    right: '0',
    top: 'auto',
    transform: 'rotateZ(90deg) translateY(-100%)',
  },
  '[data-side="right"] &': {
    left: '0',
    top: '$26',
    transform: 'rotateZ(-90deg) translateY(-100%)',
    transformOrigin: 'bottom',
  },
  '[data-side="right"][data-align="center"] &': {
    bottom: '$50',
    top: '50%',
    transform: 'rotateZ(90deg) translateY(-100%) translateX(-50%)',
  },
  '[data-side="right"][data-align="end"] &': {
    bottom: '$50',
    left: '0',
    top: 'auto',
    transform: 'rotateZ(90deg) translateY(-100%)',
  },
  '[data-side="top"] &': {
    left: '50%',
    top: 'calc(100% - $1)',
    transform: 'rotateZ(180deg) translateX(50%) translateY(100%)',
    transformOrigin: 'bottom',
  },
  alignItems: 'center',
  display: 'flex',
  height: '$11',
  justifyContent: 'center',
  position: 'absolute',
  right: '$14',
  top: '-$10',
  variants: {
    side: {
      bottom: {},
      left: {
        right: '0',
        top: '$26',
        transform: 'rotateZ(90deg) translateY(-100%)',
        transformOrigin: 'bottom',
      },
      right: {},
      top: {},
    },
    size: {
      large: {
        height: '$11',
        width: '$26',
      },
      small: {},
    },
    variant: {
      dark: {
        color: '$gray95',
      },
      light: {
        color: '$gray20',
      },
    },
  },
  width: '$26',
});

export const Tooltip = ({
  align = 'center',
  alignOffset,
  ariaLabel,
  arrow = false,
  arrowSize = 'small',
  asChild,
  children,
  content,
  css,
  disabled = false,
  forceMount,
  onClickOutside,
  onTriggerClick,
  open,
  side = 'bottom',
  sideOffset = 16,
  variant = 'dark',
}: Pick<
  TooltipContentProps,
  'align' | 'alignOffset' | 'forceMount' | 'side' | 'sideOffset'
> & {
  readonly ariaLabel?: string;
  readonly arrow?: boolean;
  readonly arrowSize?: 'large' | 'small';
  readonly asChild?: boolean;
  readonly children: ReactNode;
  readonly content: ReactNode | string;
  readonly css?: CSS;
  readonly disabled?: boolean;
  readonly onClickOutside?: () => void;
  readonly onTriggerClick?: () => void;
  readonly open?: boolean;
  readonly variant?: 'dark' | 'light';
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <Container>
      <Root
        delayDuration={100}
        open={open}
      >
        {/* We need this tab index to avoid tooltips auto-opening on modals opening */}
        <StyledTrigger
          aria-label={ariaLabel}
          asChild={asChild}
          onClick={() => onTriggerClick?.()}
          tabIndex={-1}
          type="button"
        >
          {children}
        </StyledTrigger>
        <Portal>
          <Content
            align={align}
            alignOffset={
              typeof alignOffset === 'number' ? alignOffset : arrow ? -28 : 0
            }
            css={css}
            forceMount={Boolean(forceMount) || undefined}
            hideWhenDetached
            onPointerDownOutside={() => onClickOutside?.()}
            side={side}
            sideOffset={sideOffset}
            variant={variant}
          >
            {arrow ? (
              <Arrow
                size={arrowSize}
                variant={variant}
              >
                <TooltipArrow
                  height={arrowSize === 'large' ? 11 : undefined}
                  width={arrowSize === 'large' ? 26 : undefined}
                />
              </Arrow>
            ) : null}
            {content}
          </Content>
        </Portal>
      </Root>
    </Container>
  );
